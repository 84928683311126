<template>
  <section
    v-if="Object.keys(userData).length"
    id="user-profile"
    class="user-view-wrapper"
  >
    <b-row class="user-view">
      <b-col
        cols="12"
        md="5"
        lg="4"
      >
        <div
          class="user-view-card row"
        >
          <!-- USER DETAIL CARD -->
          <div class="col col-12">
            <user-detail-card :data="userData.userDetails" />
          </div>

          <!-- USER SUBSCRIPTION STATUS -->
          <div class="col col-12">
            <user-subscription-status :data="userData.userSubscription" />
          </div>
        </div>
      </b-col>

      <b-col
        cols="12"
        md="7"
        lg="8"
      >
        <user-information
          :user-info="userData.userInfo"
          :user-c-v="userData.userCV"
        />
        <b-row>
          <b-col
            cols="12"
            lg="6"
            md="6"
          >
            <user-photo-list :images="userData.userPhoto" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol,
} from 'bootstrap-vue'
import { formatDate, avatarText } from '@core/utils/filter'
import store from '@/store'
import router from '@/router'
import userStoreModule from '../userStoreModule'
import UserDetailCard from './UserDetailCard.vue'
import UserSubscriptionStatus from './UserSubscriptionStatus.vue'
import UserPhotoList from './UserPhotoList.vue'
import UserInformation from './UserInformation.vue'

export default {
  components: {
    BRow,
    BCol,
    UserDetailCard,
    UserSubscriptionStatus,
    UserPhotoList,
    UserInformation,
  },
  directives: {
    Ripple,
  },
  setup() {
    const userData = ref({})

    const USER_APP_STORE_MODULE_NAME = 'master-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) { store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) { store.unregisterModule(USER_APP_STORE_MODULE_NAME) }
    })

    store
      .dispatch('master-user/fetchUser', { id: router.currentRoute.params.id })
      .then(response => {
        if (response.data.code === 200) {
          const data = response.data.data
          delete data.userInfo['ethnic']
          userData.value = data
        } else userData.value = undefined
      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })

    return {
      userData,
      formatDate,
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-profile.scss';
</style>
