<template>
  <app-collapse
    accordion
    type="margin"
    class="mb-2"
  >
    <app-collapse-item
      title="Info User"
      class="mt-0"
    >
      <table class="mt-2 mt-xl-0 w-100">
        <tr
          v-for="(data, key) in Object.entries(userInfo)"
          :key="key"
        >
          <th class="pb-50">
            <feather-icon
              icon="ArrowDownRightIcon"
              class="mr-75"
            />
            <span class="font-weight-bold">{{ $t('user.info.' + data[0]) }}</span>
          </th>
          <td class="pb-50">
            {{ Array.isArray(data[1]) ? data[1].join(', ') : data[1] }}
          </td>
        </tr>
      </table>
    </app-collapse-item>

    <app-collapse-item title="Curriculum Vitae">
      <table
        v-if="Array.isArray(userCV)"
        class="mt-2 mt-xl-0 w-100"
      >
        <tr
          v-for="(data, key) in Object.entries(hehe)"
          :key="key"
        >
          <th class="pb-50">
            <feather-icon
              icon="UserIcon"
              class="mr-75"
            />
            <span class="font-weight-bold">{{ $t('user.cv.' + data[0]) }}</span>
          </th>
          <td class="pb-50">
            {{ data[1] }}
          </td>
        </tr>
      </table>
      <p v-else>
        {{ userCV }}
      </p>
    </app-collapse-item>
  </app-collapse>
</template>

<script>
/* eslint-disable brace-style, guard-for-in, no-restricted-syntax */
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
  },
  props: {
    userInfo: {
      type: Object,
      required: true,
    },
    userCV: {
      type: [Array, String],
      default: () => [],
    },
  },

  setup(props) {
    const flattenObj = ob => {
      // The object which contains the
      // final result
      const result = {}

      // loop through the object "ob"

      for (const i in ob) {
        // We check the type of the i usin
        // typeof() function and recursively
        // call the function again
        if ((typeof ob[i]) === 'object' && !Array.isArray(ob[i])) {
          const temp = flattenObj(ob[i])
          for (const j in temp) {
            // Store temp in result
            result[`${i}.${j}`] = temp[j]
          }
        }

        // Else store ob[i] in result directly
        else {
          result[i] = ob[i]
        }
      }
      return result
    }

    const {
      _id, __v, created_at, updated_at, user_id, ...hehe
    } = flattenObj(props.userCV[0])

    return {
      hehe,
    }
  },
}
</script>
