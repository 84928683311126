<template>
  <b-card no-body class="pt-3">
    <b-card-title title-tag="div" class="justify-content-center flex-column">
      <b-avatar
        :src="data.image_profile"
        :text="avatarText(data.name)"
        rounded
        variant="light-primary"
        size="120px"
      />
      <div class="name text-capitalize d-flex align-items-center">
        <i
          :class="resolveUserGenderIcon(data.gender)"
          style="font-size: 16px; margin-right: 0.5rem;"
        />
        {{ data.name }}
      </div>
      <b-badge
        class="user-badge text-uppercase mt-1"
        :variant="resolveUserTypeVariant(data.type_user)"
      >
        {{ data.type_user }} Account
      </b-badge>
    </b-card-title>
    <b-card-text
      text-tag="div"
      class="d-flex justify-content-center flex-wrap mt-1 pt-0 pr-sm-0"
    >
      <div class="d-flex align-center mr-2 mb-1">
        <b-avatar rounded variant="light-primary" size="40px" class="mr-1 mb-0">
          <feather-icon icon="ArrowUpIcon" />
        </b-avatar>
        <div class="card-text-copy">
          <h3 class="">
            {{ data.pengajuan }}
          </h3>
          <span>Pengajuan CV</span>
        </div>
      </div>
      <div class="d-flex align-center mb-1 mr-1">
        <b-avatar rounded variant="light-success" size="40px" class="mr-1 mb-0">
          <feather-icon icon="ArrowDownIcon" />
        </b-avatar>
        <div class="card-text-copy">
          <h3 class="">
            {{ data.permintaan }}
          </h3>
          <span>Permintaan CV</span>
        </div>
      </div>
    </b-card-text>
    <b-card-text class="details pt-0">
      <h2 class="text-xl font-weight-semibold mb-1">
        Details
      </h2>
      <hr role="separator" />
      <table>
        <tbody>
          <tr>
            <td class="pr-1 font-weight-bold">
              Email:
            </td>
            <td>{{ data.email }}</td>
          </tr>
          <tr>
            <td class="pr-1 font-weight-bold">
              Status:
            </td>
            <td>Active</td>
          </tr>
          <tr class="text-capitalize">
            <td class="pr-1 font-weight-bold">
              Gender:
            </td>
            <td>{{ data.gender }}</td>
          </tr>
          <tr>
            <td class="pr-1 font-weight-bold">
              DoB:
            </td>
            <td>{{ formatDate(data.dob) }}</td>
          </tr>
        </tbody>
      </table>
    </b-card-text>
    <div class="card-actions justify-content-center">
      <b-button
        v-if="$can('update', 'Users') || $can('delete', 'Users')"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-danger"
      >
        Suspend Account
      </b-button>
    </div>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BCard,
  BCardTitle,
  BAvatar,
  BBadge,
  BCardText,
  BButton,
} from 'bootstrap-vue'
import { formatDate, avatarText } from '@core/utils/filter'
import useUserList from '../user-list/useUserList'

export default {
  components: {
    BCard,
    BCardTitle,
    BAvatar,
    BBadge,
    BCardText,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const { resolveUserTypeVariant, resolveUserGenderIcon } = useUserList()

    return {
      formatDate,
      avatarText,

      resolveUserTypeVariant,
      resolveUserGenderIcon,
    }
  },
}
</script>

<style scoped>
.card-title {
  align-items: center;
  display: flex;
  font-size: 1.25rem;
  flex-wrap: wrap;
  letter-spacing: 0.0125em;
  line-height: 1;
  word-break: break-all;
  padding: 20px;
  margin-bottom: 0;
}

.b-avatar {
  margin-bottom: 12px;
}

span.name {
  margin-bottom: 8px;
}

.user-badge {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.card-text {
  width: 100%;
  padding: 16px 20px;
  font-size: 0.875rem;
  line-height: 1.5;
  font-weight: 400;
}

.card-text.details {
  line-height: 2;
}

.card-actions {
  align-items: center;
  display: flex;
  padding: 16px 20px;
  padding-top: 0;
}

.card-text-copy h3 {
  margin-bottom: -2px;
  line-height: 1.75rem;
}
</style>
