<template>
  <b-card
    v-if="!isNullUndefEmptyStr"
    no-body
    class="border-primary"
  >
    <b-card-title
      title-tag="div"
      class="align-items-start justify-content-between pb-0"
    >
      <b-badge
        variant="light-warning"
        class="font-weight-bold text-sm mrb-3 text-capitalize"
      >
        {{ data.type }}
      </b-badge>
      <div class="pricing">
        <sup class="text-primary">$</sup>
        <span class="font-weight-bold display text-primary">99</span>
        <sub>/ month</sub>
      </div>
    </b-card-title>
    <b-card-text
      text-tag="div"
      class="pt-0"
    >
      <div role="list">
        <div
          tabindex="-1"
          role="listitem"
          class="px-0 mb-n2 v-list-item v-list-item--dense theme--dark"
        >
          <span
            aria-hidden="true"
            class="v-icon notranslate me-2 theme--dark"
            style="font-size: 10px; height: 10px; width: 10px;"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            role="img"
            aria-hidden="true"
            class="v-icon__svg"
            style="font-size: 10px; height: 10px; width: 10px;"
          ><path d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" /></svg></span><span>10 Quota CV</span>
        </div>
        <div
          tabindex="-1"
          role="listitem"
          class="px-0 mb-n2 v-list-item v-list-item--dense theme--dark"
        >
          <span
            aria-hidden="true"
            class="v-icon notranslate me-2 theme--dark"
            style="font-size: 10px; height: 10px; width: 10px;"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            role="img"
            aria-hidden="true"
            class="v-icon__svg"
            style="font-size: 10px; height: 10px; width: 10px;"
          ><path d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" /></svg></span><span>250 Swipes</span>
        </div>
        <div
          tabindex="-1"
          role="listitem"
          class="px-0 mb-n2 v-list-item v-list-item--dense theme--dark"
        >
          <span
            aria-hidden="true"
            class="v-icon notranslate me-2 theme--dark"
            style="font-size: 10px; height: 10px; width: 10px;"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            role="img"
            aria-hidden="true"
            class="v-icon__svg"
            style="font-size: 10px; height: 10px; width: 10px;"
          ><path d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" /></svg></span><span>Open locked photo</span>
        </div>
      </div>
      <p class="d-flex font-weight-bold text-primary justify-content-between">
        <span>Days</span>
        <span>26 of 30 Days</span>
      </p>
      <div class="progress-bar-plan">
        <b-progress
          value="26"
          max="30"
        />
        <label class="text-muted">4 days remaining</label>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
      >Upgrade</b-button>
    </b-card-text>
  </b-card>
  <b-card
    v-else
    class="border-primary"
  >
    No Subscription
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BCard, BCardTitle, BBadge, BCardText, BButton, BProgress,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BBadge,
    BCardText,
    BButton,
    BProgress,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isNullUndefEmptyStr = Object.values(props.data).every(value => {
      // 👇️ check for multiple conditions
      if (value === null || value === undefined || value === '') {
        return true
      }
      return false
    })

    return {
      isNullUndefEmptyStr,
    }
  },
}
</script>

<style scoped>
.card-title {
  align-items: center;
  display: flex;
  font-size: 1.25rem;
  flex-wrap: wrap;
  letter-spacing: .0125em;
  line-height: 1;
  word-break: break-all;
  padding: 20px;
  margin-bottom: 0;
}

.card-text {
  width: 100%;
  padding: 16px 20px;
  font-size: .875rem;
  line-height: 1.5;
  font-weight: 400;
}

.text-sm {
  font-size: 12px;
}

.mrb-3 {
  margin-right: 12px;
  margin-bottom: 12px;
}

.pricing sup {
  top: -1.5rem;
  font-size: .8rem;
  margin-right: .2rem;
}

.pricing sub {
  top: -0.2rem;
}

.display {
  font-size: 3rem;
  line-height: 1;
}

.v-list--dense .v-list-item, .v-list-item--dense {
    min-height: 40px;
}

.v-list-item {
    align-items: center;
    display: flex;
    flex: 1 1 100%;
    letter-spacing: normal;
    min-height: 48px;
    outline: none;
    padding: 0 16px;
    position: relative;
    text-decoration: none;
}

.me-2 {
  margin-right: 8px;
}

.v-icon.v-icon {
    align-items: center;
    display: inline-flex;
    font-feature-settings: "liga";
    font-size: 24px;
    justify-content: center;
    letter-spacing: normal;
    line-height: 1;
    position: relative;
    text-indent: 0;
    transition: .3s cubic-bezier(.25,.8,.5,1),visibility 0s;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.v-icon__svg {
    fill: currentColor;
}

.card-text p {
  margin-top: 20px;
  margin-bottom: 8px;
}

.progress-bar-plan label {
  margin-top: 8px;
  margin-bottom: 16px;
}
</style>
