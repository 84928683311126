<template>
  <b-card>
    <h5 class="mb-0">
      User Images
    </h5>
    <b-row>
      <!-- latest photo loop -->
      <b-col
        v-for="data in images"
        :key="data.path"
        md="3"
        cols="6"
        class="profile-latest-img"
      >
        <b-link>
          <b-img
            fluid
            rounded
            :src="data.path"
            :alt="data.path.slice(5)"
          />
        </b-link>
      </b-col>
      <!-- latest photo loop -->
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BLink, BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BLink,
    BImg,
  },
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
